function loadImage({
  src,
  width,
  quality,
}: {
  src: string
  width: number
  quality?: string
}): string {
  const cloudinaryOrgId = 'abatable-com'

  const root = `https://res.cloudinary.com/${cloudinaryOrgId}/image/upload/`
  const params = ['f_auto', 'c_limit', `w_${width}`, `q_${quality ?? 'auto'}`]
  const paramsString = params.join(',') + '/'

  return `${root}${paramsString}${normalizeSrc(src)}`
}

function normalizeSrc(src: string): string {
  return src.startsWith('/') ? src.slice(1) : src
}

const cloudinaryService = {
  loadImage,
}

export { cloudinaryService }
